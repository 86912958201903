.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
}

.Header {
    margin: 32px;
}

.Row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    height: 64px;
}

p {
    margin: 0;
}

.BasicTracker {
    border: 1px solid black;
    padding: 16px;
    margin-bottom: 128px;
}
