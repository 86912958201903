article {
    max-width: 55rem;
    display: block;
    margin: 0 auto;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    word-wrap: break-word;
}

article * {
    max-width: 100%;
}

p {
    margin: 0.25rem 0;
}

h1 {
    font-size: 2rem;
    line-height: 1.5;
    font-weight: normal;
    border-bottom: 1px solid #eee;
    margin: 1rem 0 0.25rem 0;
}

h2 {
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: normal;
    border-bottom: 1px solid #eee;
    margin: 1rem 0 0.25rem 0;
}

h3 {
    font-size: 1.25rem;
    line-height: 1.5;
    font-weight: normal;
    border-bottom: none;
    margin: 1rem 0 0.25rem 0;
}

h4 {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bold;
    border-bottom: none;
    margin: 1rem 0 0.25rem 0;
}

h5 {
    font-size: 0.8rem;
    line-height: 1.5;
    font-weight: bold;
    border-bottom: none;
    margin: 1rem 0 0.25rem 0;
}

h6 {
    font-size: 0.75rem;
    line-height: 1.5;
    font-weight: bold;
    border-bottom: none;
    margin: 1rem 0 0.25rem 0;
}

article > p > a#link-to-linkedin {
    padding: 0 0 0 0.5rem;
}
