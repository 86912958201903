.Registration {
    display: flex;
    flex: 1;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

fieldset {
    display: flex;
    width: 480px;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex-direction: column;
}

label {
    margin-bottom: 8px;
    font-size: 14px;
}

sup {
    color: red;
    top: -2px;
    font-size: 95%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

input,
select {
    border-radius: 4px;
    border: 1px solid #ccc;
    height: 32px;
    padding: 0 8px;
}

.Field {
    position: relative;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
}

.FieldError {
    position: absolute;
    bottom: -6px;
    color: red;
    font-size: 12px;
}

.Row {
    display: flex;
    flex-direction: row;
}

button {
    padding: 12px;
    width: 240px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: darkblue;
    color: lightblue;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

button:disabled,
button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}
