* {
    font-family: sans-serif;
}
input,
button {
    font-size: 20px;
    padding: 10px;
    border-radius: 5px;
}
input {
    display: block;
    margin-bottom: 20px;
}
button {
    border: 1px solid gray;
    background: whitesmoke;
    margin-right: 5px;
}
button:nth-last-child(2),
button:nth-last-child(1) {
    background: tomato;
    color: white;
}
